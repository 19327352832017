
import get from 'lodash/get'
import { mapState } from 'vuex'
import { socialData } from '~/utils'
import SocialIcon from '~/components/Icons/SocialIcon.vue'
import omit from 'lodash/omit'

const misc02 = require('~/assets/images/landing-page-creator/misc-02.png')

const googlePlayDownloadButtonImage = require('~/assets/images/get-it-on-google-play.png')
const appStoreDownloadButtonImage = require('~/assets/images/download-on-the-app-store.png')

export default {
  components: { SocialIcon },
  data() {
    return {
      isMounted: false,
      bottomMenusRight: [
        { key: 'termsOfService', text: 'termsService', url: '/p/terms-of-service' },
        { key: 'privacyPolicy', text: 'privacyPolicy', url: '/p/privacy-policy' },
        { key: 'refundPolicy', text: 'refundPolicy', url: '/p/refund-policy' },
        { key: 'faq', text: 'faq', url: '/p/faq' },
        {
          key: 'blog', text: 'blog', url: 'https://ganknow.com/blog/', type: 'href',
        },
        {
          key: 'creatorUniversity', text: 'creatorUniversity', url: 'https://learn.ganknow.com/', tracker: ['university_link_footer', 'university_link'], type: 'href',
        },
      ],
      socialData,
      currentDate: new Date(),
      address: [
        {
          countryCode: 'all',
          company: 'Gank Pte. Ltd.',
          address: '9 North Buona Vista Drive, #02-01, Metropolis Tower 1',
          city: 'Singapore 138588',
        },
        {
          countryCode: 'TH',
          company: 'Creatorverse Co., Ltd.',
          address: '94/117 Moo. 12, Sridaan 22,',
          city: 'Bangkaew, Bangplee, Samutprakarn,',
          country: '10540, Thailand',
        },
        {
          countryCode: 'TW',
          company: '新加坡商聚創國際有限公司台灣分公司',
          address: '統一編號：93702646',
        },
      ],
      downloadAppButton: [
        {
          key: 'google-play',
          image: googlePlayDownloadButtonImage,
          alt: 'Get it on Google Play',
          url: 'https://play.google.com/store/apps/details?id=com.ganknow.app&hl=en&gl=US',
        },
        {
          key: 'app-store',
          image: appStoreDownloadButtonImage,
          alt: 'Download on the App Store',
          url: 'https://apps.apple.com/sg/app/creator-companion-app/id6446162254',
        },
      ],
      misc02,
    }
  },
  computed: {
    ...mapState({
      country: state => state.settings.countryUser,
    }),
    currentYear() {
      return new Date().getFullYear()
    },
    hideFooter() {
      const paths = ['/chats']
      return paths.includes(this.$route.path)
    },
    getAddress() {
      return omit(this.address.find(item => item.countryCode === this.country) || this.address[0], ['countryCode'])
    },
    footerMiscAssetVisibility() {
      const list = [
        'index',
        'streamers',
        'artist',
        'cosplayers',
      ]

      return !this.$auth.loggedIn && list.includes(this.$route.name)
    },
  },
  mounted() {
    setTimeout(() => {
      this.isMounted = true
    }, 1000)
  },
  methods: {
    isShowDark() {
      if (this.$route.name !== 'nickname') return true
      if (this.$route.name === 'nickname' && this.$colorMode.preference === 'dark') return true
      return false
    },
    downloadAppButtonGtm(type) {
      this.$gtm.push({
        event: type === 'google-play' ? 'download_on_google_play' : 'download_on_app_store',
      })
    },
    onClickTrackEvent(eventName) {
      if (!eventName) {
        console.warn('No event name provided for tracking.')
        return
      }
      const pushEvent = name => {
        this.$gtm.push({
          event: name,
          user_id: get(this.$auth, 'user.id') || '',
        })
      }

      if (typeof eventName === 'object') {
        eventName.forEach(pushEvent)
      } else {
        pushEvent(eventName)
      }
    },
  },
}
